<template>
  <v-card>
    <v-card-title class="justify-center mb-4">
      Filtrar termos
    </v-card-title>

    <v-card-text>
      <div class="separator">
        <div
          class="label"
        >
          <span>informações do cliente</span>
        </div>
      </div>
      <v-form ref="form">
        <v-row>
          <div
            class="line"
          >
            <v-text-field
              v-model="client_name"
              label="Nome do cliente"
              outlined
              dense
            />
          </div>
          <div class="line">
            <v-text-field
              v-model="cpf_cnpj"
              label="CPF/CNPJ"
              outlined
              dense
            />

            <v-text-field
              v-model="client_phone"
              label="Telefone"
              outlined
              dense
            />

            <v-text-field
              v-model="proposal_number"
              label="Número da proposta"
              outlined
              dense
            />
          </div>
        </v-row>

        <div class="separator">
          <div
            class="label"
          >
            <span>informações do contrato</span>
          </div>
        </div>

        <v-row>
          <v-col span="12">
            <v-dialog
              ref="dialogDateTimeInitialDateContract"
              v-model="showCalendarInitialDateContract"
              :return-value.sync="initial_date_contract"
              width="400px"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedInitialDateContract"
                  :append-icon="icons.mdiCalendar"
                  label="Data inicial contrato"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                />
              </template>

              <v-date-picker
                v-model="initial_date_contract"
                width="400px"
                locale="pt-BR"
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="showCalendarInitialDateContract = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogDateTimeInitialDateContract.save(initial_date_contract)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-dialog
              ref="dialogDateTimeFinalDateContract"
              v-model="showCalendarFinalDateContract"
              :return-value.sync="final_date_contract"
              width="400px"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedFinalDateContract"
                  :append-icon="icons.mdiCalendar"
                  label="Data final contrato"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                />
              </template>

              <v-date-picker
                v-model="final_date_contract"
                width="400px"
                locale="pt-BR"
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="showCalendarFinalDateContract = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogDateTimeFinalDateContract.save(final_date_contract)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <!-- <v-text-field
              v-model="initial_date_contract"
              label="Data inicial contrato"
              outlined
              dense
            /> -->
            <!--
            <v-text-field
              v-model="final_date_contract"
              label="Data final contrato"
              outlined
              dense
            /> -->
          </v-col>
          <v-col span="12">
            <v-dialog
              ref="dialogDateTimeInitialDateValidated"
              v-model="showCalendarInitialDateValidated"
              :return-value.sync="initial_date_validated"
              width="400px"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedInitialDateValidated"
                  :append-icon="icons.mdiCalendar"
                  label="Data de validação Inicial"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                />
              </template>

              <v-date-picker
                v-model="initial_date_validated"
                width="400px"
                locale="pt-BR"
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="showCalendarInitialDateValidated = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogDateTimeInitialDateValidated.save(initial_date_validated)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-dialog
              ref="dialogDateTimeFinalDateValidated"
              v-model="showCalendarFinalDateValidated"
              :return-value.sync="final_date_validated"
              width="400px"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedFinalDateValidated"
                  :append-icon="icons.mdiCalendar"
                  label="Data de validação Final"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                />
              </template>

              <v-date-picker
                v-model="final_date_validated"
                width="400px"
                locale="pt-BR"
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="showCalendarFinalDateValidated = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogDateTimeFinalDateValidated.save(final_date_validated)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <div
          class="stand-by"
        >
          <v-switch
            v-model="bid_fixed"
            color="purple"
          />

          <p>Lance fixo</p>

          <v-switch
            v-model="life_insurance"
            class="ml-4"
            color="purple"
          />

          <p>Seguro de vida</p>
        </div>
      </v-form>

      <div class="d-flex justify-end mt-4">
        <v-btn
          color="error"
          @click="closeModal(), resetForm()"
        >
          Fechar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="getFilterData()"
        >
          <span v-if="!isLoading">Filtrar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiCalendar, mdiClose } from '@mdi/js'

export default {
  mixins: [formatters, messages, utilsMixin],

  data() {
    return {
      icons: {
        mdiClose,
        mdiCalendar,
      },
      showCalendarInitialDateContract: false,
      showCalendarFinalDateContract: false,
      showCalendarFinalDateValidated: false,
      showCalendarInitialDateValidated: false,
      proposal_number: '',
      client_name: '',
      cpf_cnpj: '',
      client_phone: '',
      initial_date_contract: '',
      final_date_contract: '',
      bid_fixed: '',
      life_insurance: '',
      initial_date_validated: '',
      final_date_validated: '',

      leaders: [],
      companies: [],
      employees: [],

      isLoading: false,
    }
  },

  computed: {
    computedInitialDateContract: {
      get() {
        return (this.initial_date_contract)
      },

      set() {},
    },
    computedFinalDateContract: {
      get() {
        return (this.final_date_contract)
      },

      set() {},
    },
    computedInitialDateValidated: {
      get() {
        return (this.initial_date_validated)
      },

      set() {},
    },
    computedFinalDateValidated: {
      get() {
        return (this.final_date_validated)
      },

      set() {},
    },
  },

  created() {
  },

  methods: {
    async getFilterData() {
      const {
        // eslint-disable-next-line camelcase
        proposal_number, client_name, cpf_cnpj, client_phone, initial_date_contract, final_date_contract, bid_fixed, life_insurance, initial_date_validated, final_date_validated,
      } = this

      const nameClient = client_name.toUpperCase()

      const body = {
        proposal_number, client_name: nameClient, cpf_cnpj, client_phone, initial_date_contract, final_date_contract, bid_fixed, life_insurance, initial_date_validated, final_date_validated,
      }

      if (this.hasNonEmptyKey(body)) {
        this.isLoading = true

        await axiosIns
          .post('/api/v1/integration/lgpd_term/find_term', body)
          .then(response => {
            const { data: filteredData } = response.data

            if (filteredData.length === 0) {
              this.showMessage({
                icon: 'warning',
                title: 'Nenhum registro encontrado!',
                text: 'Tente novamente',
              })

              return
            }

            this.showMessage({ title: 'Sucesso', text: 'Dados obtidos com sucesso', icon: 'success' })
            this.resetForm()
            this.closeModal()
            this.sendFilteredData(response, body)
          })
          .catch(error => this.showMessage({
            title: 'Contate a equipe de desenvolvimento, erro ao enviar dados',
            text: error.message,
            icon: 'error',
          }))
          .finally(() => {
            this.isLoading = false
          })

        return
      }

      this.showMessage({ title: 'Campos vazios', text: 'Você deve preencher ao menos um dos campos' })
    },

    sendFilteredData(data, body) {
      this.$emit('filteredData', data)
      this.$emit('filteredBody', body)
    },

    resetForm() {
      const { form } = this.$refs
      form.reset()

      this.proposal_number = ''
      this.client_name = ''
      this.cpf_cnpj = ''
      this.client_phone = ''
      this.initial_date_contract = ''
      this.final_date_contract = ''
      this.bid_fixed = ''
      this.life_insurance = ''
      this.initial_date_validated = ''
      this.final_date_validated = ''
    },
  },
}
</script>
<style>
.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-bottom: 20px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  padding: 10px;

  & + & {
    margin-top: -25px
  }
}

.stand-by{
  display: flex;
  align-items: center;

  margin-top: -30px;

  p{
    margin-top: 12px;
  }
}
</style>
